import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import PieSocket from 'piesocket-js';
import { addWhatsappNotifCount, refreshThreadMessages } from 'src/redux/slices/whatsapp';
import { setPersistedWpNotifications } from 'src/redux/slices/liveNotificationSlice';
import { fetchMergeTagKeys, fetchMergeTagValueForPractice, fetchMergeTagValueForUser, fetchPracticeUsers } from 'src/redux/slices/mergeTags';
import { useResponsive } from '../../hooks';
import { useSettingsContext } from '../../components/settings/context';
import Main from './Main';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import NavMini from './navbar/NavMini';

export default function DashboardLayout() {
    const { themeLayout } = useSettingsContext();
    const pieSocket = React.useRef(null);
    const dispatch = useDispatch();
    const { jwtToken, practiceMetaData } = useSelector(
        (state) => ({
            jwtToken: state?.whatsApp?.jwtToken,
            practiceMetaData: state?.whatsApp?.practiceMetaData,
        }),
        shallowEqual
    );
    const isDesktop = useResponsive('up', 'lg');
    const [open, setOpen] = useState(false);
    const horizontalLayout = themeLayout === 'horizontal';
    const isNavMini = themeLayout === 'mini';

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderNavVertical = <NavbarVertical openNav={open} onCloseNav={handleClose} />;

    const { practiceId, allAvlPhoneData } = practiceMetaData ?? {};
    React.useEffect(() => {
        if (jwtToken && pieSocket?.current === null && practiceId && allAvlPhoneData) {
            pieSocket.current = new PieSocket({
                clusterId: import.meta.env.VITE_REACT_APP_SOCKET_CLUSTER_KEY || '',
                apiKey: import.meta.env.VITE_REACT_APP_SOCKET_API_KEY || '',
                jwt: jwtToken,
            });
            Object.values(allAvlPhoneData ?? {})?.forEach((businessAccount) => {
                businessAccount?.forEach((phoneId) => {
                    pieSocket.current.subscribe(`${practiceId}_${phoneId}`).then((channel) => {
                        channel.listen('new-whatsapp-message', (data) => {
                            if (phoneId !== data?.from) {
                                dispatch(setPersistedWpNotifications(data));
                                dispatch(addWhatsappNotifCount(data?.from));
                            }
                            dispatch(refreshThreadMessages(-1));
                        });
                    });
                });
            });
        } else if (jwtToken === null) {
            Object.values(allAvlPhoneData ?? {})?.forEach((businessAccount) => {
                businessAccount?.forEach((phoneId) => {
                    pieSocket.current?.unsubscribe?.(`${practiceId}_${phoneId}`);
                });
            });
            pieSocket.current = null;
        }
    }, [allAvlPhoneData, dispatch, jwtToken, practiceId]);

    React.useEffect(() => {
        // Loading Merge Tags in First Load for use accross application
        dispatch(fetchMergeTagKeys());
        dispatch(fetchMergeTagValueForPractice());
        dispatch(fetchPracticeUsers());
        dispatch(fetchMergeTagValueForUser());
    }, [dispatch, practiceId])


    if (horizontalLayout) {
        return (
            <>
                <DashboardHeader onOpenSidebar={handleOpen} />

                {isDesktop ? <NavbarHorizontal /> : renderNavVertical}

                <Main>
                    <Outlet />
                </Main>
            </>
        );
    }

    if (isNavMini) {
        return (
            <>
                <DashboardHeader onOpenSidebar={handleOpen} />

                <Box
                    sx={{
                        display: { lg: 'flex' },
                        minHeight: { lg: 1 },
                    }}
                >
                    {isDesktop ? <NavMini /> : renderNavVertical}

                    <Main>
                        <Outlet />
                    </Main>
                </Box>
            </>
        );
    }

    return (
        <>
            <DashboardHeader onOpenSidebar={handleOpen} />

            <Box
                sx={{
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 },
                }}
            >
                {renderNavVertical}

                <Main>
                    <Outlet />
                </Main>
            </Box>
        </>
    );
}
